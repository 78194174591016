/* You can add global styles to this file, and also import other style files */
// @import "~@angular/material/prebuilt-themes/pink-bluegrey.css";

@import "scss/variables";
@import "scss/fonts";
@import "scss/theme";
@import "scss/layout";
@import "scss/forms";
@import "scss/tabs";
@import "scss/tables";
@import "scss/charts";

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

a,
a:visited {
    color: mat-color($psl-app-accent);
    text-decoration: none;
}

a:hover,
a:visited:hover,
a:active,
a:focus {
    text-decoration: underline;
    color: darken(mat-color($psl-app-accent), 10%);
}

button.mat-menu-item {
    line-height: 24px !important;
}

a.mat-menu-item > mat-icon {
    margin-bottom: 14px;
}

.no-wrap {
    white-space: nowrap;
}