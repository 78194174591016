body {
  margin: 0;
  background: $body-bg;
  font-size: mat-font-size($psl-custom-typography, body-1);
  font-family: mat-font-family($psl-custom-typography);
  color: mat-color($psl-primary-color, 500);
}

.boxed-in {
  background: #fff;
  padding: 25px;
}

.heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-top: -5px;

  h1 {
    text-transform: uppercase;
    font-size: 1.25rem;
    margin: 0;
  }
}

.container {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid $border-color;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    .new-button {
      padding-left: 40px;
      padding-right: 40px;

      mat-icon {
        margin-left: 15px;
        height: 30px;
        width: 30px;
      }
    }
  }

  .table-container {
    width: 100%;
    table {
      width: 100%;
      border-collapse: true;
      border: 1px solid #cdcdcd;

      .mat-header-row {
        height: 30px;
        background-color: #f8f8f8;

        .mat-header-cell,
        ::ng-deep .mat-sort-header-button {
          text-transform: uppercase;
        }
      }

      #buttons-cell {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
}

.reports {
  .pagination {
    display: flex;
    flex-direction: row;
    align-items: center;

    .current {
      padding: 0 30px;
      font-size: 1.25rem;
    }

    button {
      text-transform: uppercase;

      ::ng-deep .mat-button-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
}

.notes_icon{
  width: 24px;
  height:24px;
  margin-left:5px;
}