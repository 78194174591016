.table {
    table {
        width: 100%;
        border-collapse: collapse;

    }

    th {
        text-transform: uppercase;
        font-size:0.8rem;
        text-align: left;
    }

    tr.active {
        border-top: 2px solid mat-color($psl-app-accent) !important;
        border-bottom: 2px solid mat-color($psl-app-accent) !important;
        border-left: 2px solid mat-color($psl-app-accent) !important;
        border-right: 2px solid mat-color($psl-app-accent) !important;
    }

    td {
        border-top: 1px solid $border-color;
        font-weight: normal;

        &:last-child {
            text-align: right;
        }
    }

    th,
    td {
        padding: 10px 20px;
    }
}